<template>
    <el-container>
        <el-container>
            <!-- 中间内容 -->
            <el-main>
                <h2 class="depTitle">{{listTit}}</h2>
                <div class="hosrow">
                    <searchDic @searchList="searchList" @searChange="searChange"  />
                    <div>
                        <el-button type="primary" @click="depaddKeshi">新增附加职责</el-button>
                    </div>
                </div>
                <el-table :data="tableData" border center>
                    <el-table-column type="index" label="序号" width="55" align="center">
                    </el-table-column>
                    <el-table-column prop="name" label="指标名称" align="center">
                    </el-table-column>
                    <el-table-column prop="explain" label="指标说明" align="center" width="300">
                        <template slot-scope="scope">
                            <div class="remakStree">{{scope.row.explain}}</div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="status" label="指标状态" align="center" width="100">
                        <template slot-scope="scope">
                            <!-- 指标状态（0启用  1停用） -->
                            <div v-if="scope.row.status=='0'">
                                <el-switch v-model="value" active-color="#409EFF" inactive-color="#929292" disabled>
                                </el-switch>
                            </div>
                            <div v-else-if="scope.row.status=='1'">
                                <el-switch v-model="novalue" active-color="#409EFF" inactive-color="#929292" disabled>
                                </el-switch>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column label="操作" align="center">
                        <template style="display: flex" slot-scope="scope">
                            <el-button size="mini" type="primary" @click="tartick(scope.row)"><i class="el-icon-tickets"></i></el-button>
                            <el-button size="mini" type="primary" @click="editKeshi(scope.row)"><i class="el-icon-edit"></i></el-button>
                            <el-button size="mini" type="danger" @click="deleteKeshi(scope.row)"><i class="el-icon-delete-solid"></i></el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination background @current-change="handleCurrentChange" :current-page="pageNum" :page-size="pageSize" layout="prev, pager, next" :total="total">
                </el-pagination>
            </el-main>
        </el-container>

        <el-dialog :title="diaTit" :visible.sync="AdddialogVisible" width="360px" top="1vh" :before-close="AdddialogVisibleClose">
            <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
                <el-form-item label="指标名称：" prop="name">
                    <el-select v-model="ruleForm.name" :disabled='disabled' placeholder="请选择指标名称" @change="nameChange(ruleForm.name)">
                        <el-option v-for="item in nameoptions" :key="item.dictId" :label="item.dictName" :value="item.dictName">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="指标说明：" prop="remark">
                    <el-input type="textarea" maxlength="250" show-word-limit v-model="ruleForm.remark" placeholder="请填写指标说明"></el-input>
                </el-form-item>

                <el-form-item label="指标状态：" prop="status">
                    <el-switch v-model="ruleForm.status" active-color="#409EFF" inactive-color="#929292" active-value="0" inactive-value="1">
                    </el-switch>
                </el-form-item>

                <div class="btnCen">
                    <el-button v-if="addDepClick" type="primary" :loading="addDepClickKing" @click="addKeshi">{{rulBtnTit}}</el-button>
                     <el-button v-else type="info" :loading="addDepClickKing"></el-button>
                </div>
            </el-form>
        </el-dialog>
        <!-- 查看职责 -->
        <el-dialog :title="dytyTit" :visible.sync="dutydialogVisible" width="90%" top="1vh" :before-close="dutydialogVisibleClose">
            <div class="threSeCla">
                <searchDic @searchList="thrseList" ref="searchdic" @searChange="thrseChange" />
                <el-button type="primary" class="addtarget" @click="addTarClick">新增指标</el-button>
            </div>
            <el-table :data="dutytableData" border center>
                <el-table-column type="index" label="序号" width="55" align="center">
                </el-table-column>
                <el-table-column prop="name" label="指标名称" align="center">
                </el-table-column>
                <el-table-column prop="intension" label="指标内涵" align="center" width="300">
                    <template slot-scope="scope">
                        <div class="remakStree">{{scope.row.intension}}</div>
                    </template>
                </el-table-column>
                <el-table-column prop="suggestedScore" label="建议分值" align="center" width="80">
                </el-table-column>
                <el-table-column prop="dataType" label="指标数据类型" align="center" width="110">
                    <template slot-scope="scope">
                        <div v-if="scope.row.dataType=='0'">医生</div>
                        <div v-else-if="scope.row.dataType=='1'">护士</div>
                        <div v-else-if="scope.row.dataType=='2'">技师</div>
                        <div v-else-if="scope.row.dataType=='3'">通用</div>
                    </template>
                </el-table-column>
                <el-table-column prop="characteristic" label="特性" align="center" width="80">
                    <!-- 指标特性（0加分 1减分 2固定分值  3评分） -->
                    <template slot-scope="scope">
                        <div v-if="scope.row.characteristic=='0'">系数加分</div>
                        <div v-else-if="scope.row.characteristic=='1'">系数减分</div>
                        <div v-else-if="scope.row.characteristic=='2'">固定金额</div>
                        <div v-else-if="scope.row.characteristic=='3'">评分</div>
                    </template>
                </el-table-column>
                <el-table-column prop="unit" label="单位" align="center" width="70">
                    <!-- 单位（0次  1人/次  2半天/次） -->
                    <template slot-scope="scope">
                        <div v-if="scope.row.unit=='0'">次</div>
                        <div v-else-if="scope.row.unit=='1'">人/次</div>
                        <div v-else-if="scope.row.unit=='2'">半天/次</div>
                    </template>
                </el-table-column>
                <el-table-column prop="explain" label="指标说明" align="center" width="300">
                    <template slot-scope="scope">
                        <div class="remakStree">{{scope.row.explain}}</div>
                    </template>
                </el-table-column>
                <el-table-column prop="status" label="指标状态" align="center" width="100">
                    <template slot-scope="scope">
                        <!-- 指标状态（0启用  1停用） -->
                        <div v-if="scope.row.status=='0'">
                            <el-switch v-model="value" active-color="#409EFF" inactive-color="#929292" disabled>
                            </el-switch>
                        </div>
                        <div v-else-if="scope.row.status=='1'">
                            <el-switch v-model="novalue" active-color="#409EFF" inactive-color="#929292" disabled>
                            </el-switch>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column label="操作" align="center" width="130" fixed="right">
                    <template style="display: flex" slot-scope="scope">
                        <el-button size="mini" type="primary" @click="thiredit(scope.row)"><i class="el-icon-edit"></i></el-button>
                        <el-button size="mini" type="danger" @click="thirdelete(scope.row)"><i class="el-icon-delete-solid"></i></el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination background @current-change="thirdhandleCurrentChange" :current-page="thirpageNum" :page-size="thirpageSize" layout="prev, pager, next" :total="thirtotal">
            </el-pagination>
        </el-dialog>
        <el-dialog :title="titAdd" :visible.sync="targetdialogVisible" width="400px" top="1vh" :before-close="tarhetdialogVisibleClose">
            <secondadd :dytyTit='dytyTit' :dicTypeVal='dicTypeVal' :diaBtn='diaBtn' :secondId='secondId' :dictType='dictType' :wardId='wardId' :listTit='listTit' ref='addinman' :EditDateModel='AddDateModel' @listworkIn='tickKeshi' />
        </el-dialog>
    </el-container>
</template>

<script>
import _qs from "qs";
import secondadd from "../AddInMan/secondadd.vue";
import searchDic from "../AddInMan/searchDic.vue";
export default {
    data() {
        return {
            titAdd: "新增指标",
            secondId: "",
            disabled: false,
            thirpageNum: 1,
            thirpageSize: 10,
            thirtotal: 1,
            targetdialogVisible: false,
            addDepClick: true,
            addDepClickKing: false,
            rulBtnTit: "新增",
            ruleForm: {
                name: "",
                remark: "",
                status: "0",
            },
            rules: {
                name: [
                    {
                        required: true,
                        message: "请输指标名称",
                        trigger: "blur",
                    },
                ],
                remark: [
                    {
                        required: true,
                        message: "请输入指标说明",
                        trigger: "blur",
                    },
                ],
            },
            nameoptions: [],
            dytyTit: "",
            dutytableData: [],
            dutydialogVisible: false,
            listTit: "附加职责",
            value: true,
            novalue: false,
            diaBtn: "新增",
            diaTit: "新增附加职责",
            // 点击添加科室弹出框
            AdddialogVisible: false,
            AddDateModel: {},
            // 当前页数
            pageNum: 1,
            // 每页显示条数
            pageSize: 10,
            // 总数
            total: 1,
            // 表格数据
            tableData: [],
            // 科室id
            departmentId: window.sessionStorage.getItem("departmentId"),
            wardId: "", //病区id
            nameLike: "",
            searstatus: "",
            dieId: 5,
            dictType: "",
            dytyId: "",
            indicatorLibraryId: "",
            dicTypeVal: "",
            ticSeName: "",
            ticSeStatus: "",
        };
    },
    components: {
        secondadd,
        searchDic,
    },
    created() {
        this.rewardList();
    },
    methods: {
        thirdhandleCurrentChange(val) {
            this.thirpageNum = val;
            this.tickKeshi();
        },
        tarhetdialogVisibleClose() {
            this.targetdialogVisible = false;
            this.$refs.addinman.clearadd();
            this.diaBtn = "新增";
            this.titAdd = "新增指标";
        },
        addTarClick() {
            this.targetdialogVisible = true;
            this.diaBtn = "新增";
            this.titAdd = "新增指标";
            setTimeout(() => {
                this.$refs.addinman.dicTypeChange();
                this.$refs.addinman.perdindBy();
            }, 0);
        },
        nameChange(val) {
            // console.log(val);
            this.ruleForm.name = val;
            var obj = this.nameoptions;
            obj.forEach((item, index) => {
                if (item.dictName == val) {
                    // console.log(item);
                    this.dictType = item.dictType;
                }
            });
        },
        async sysDictType() {
            let data = _qs.stringify({
                dataType: "1",
            });

            let { data: res } = await this.$axios.sysDictType(data);
            // console.log(res);
            if (res.code == 200) {
                this.nameoptions = res.data;
            }
        },
        addKeshi() {
            if (this.rulBtnTit == "新增") {
                this.seconadd();
            } else if (this.rulBtnTit == "修改") {
                this.thirdUpdate();
            }
        },
        thiredit(row) {
            this.targetdialogVisible = true;
            // console.log(row);
            this.secondId = row.indicatorSecondLibraryId;
            this.AddDateModel = row;
            this.wardId = row.id;
            this.diaBtn = "修改";
            this.titAdd = "修改指标";
            setTimeout(() => {
                this.$refs.addinman.perdindBy();
            }, 0);
        },
        async thirdelete(row) {
            const confirmResult = await this.$confirm("确认删除？", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            }).catch((err) => err);

            if (confirmResult !== "confirm") {
                return this.$message.info("已经取消删除");
            }
            let data = _qs.stringify({
                id: row.id,
            });
            let { data: res } = await this.$axios.thirDel(data);
            // console.log(res);
            if (res.code == 401) {
                this.$router.push("/login");
            } else if (res.code == 200) {
                this.tickKeshi();
                this.$message({
                    message: res.msg,
                    type: "success",
                });
            } else {
                this.$message({
                    message: res.msg,
                    type: "error",
                });
            }
        },
        tartick(row) {
            // console.log(row);
            this.dytyTit = row.name;
            this.dytyId = row.id;
            this.secondId = row.id;
            this.dutydialogVisible = true;
            this.tickKeshi();
            this.optionselect(row.name);
        },
        async optionselect(val) {
            let data = {
                dataType: "1",
            };

            let { data: res } = await this.$axios.optionselect(data);
            // console.log(res);
            if (res.code == 200) {
                this.addoptions = res.data;
                this.addoptions.forEach((item) => {
                    if (item.dictName == val) {
                        this.dicTypeVal = item.dictType;
                    }
                });
            }
        },
        async thirdUpdate() {
            this.$refs.ruleForm.validate(async (valid) => {
                if (!valid) return;
                this.addDepClick = false;
                this.addDepClickKing = true;
                let data = _qs.stringify({
                    id: this.dytyId, //指标id
                    indicatorLibraryId: this.indicatorLibraryId, //指标类型（0基本指标 1工作量指标 2专项奖励指标 3单项补助及奖惩指标  4个人考勤指标  5附加职责）
                    name: this.ruleForm.name, //指标名称
                    explain: this.ruleForm.remark, //指标说明
                    status: this.ruleForm.status, //状态（0启用  1停用）
                    dictType: this.dictType, //字典类型
                });
                let { data: res } = await this.$axios.thirdUpdate(data);
                // console.log(res);
                this.addDepClick = true;
                this.addDepClickKing = false;
                if (res.code == 401) {
                    this.$router.push("/login");
                } else if (res.code == 200) {
                    this.$message({
                        message: res.msg,
                        type: "success",
                    });
                    this.rewardList();
                    this.AdddialogVisibleClose();
                } else {
                    this.$message({
                        message: res.msg,
                        type: "error",
                    });
                }
            });
        },
        async seconadd() {
            this.$refs.ruleForm.validate(async (valid) => {
                if (!valid) return;
                this.addDepClick = false;
                this.addDepClickKing = true;
                let data = _qs.stringify({
                    indicatorLibraryType: this.dieId, //指标类型（0基本指标 1工作量指标 2专项奖励指标 3单项补助及奖惩指标  4个人考勤指标  5附加职责）
                    name: this.ruleForm.name, //指标名称
                    explain: this.ruleForm.remark, //指标说明
                    status: this.ruleForm.status, //状态（0启用  1停用）
                    dictType: this.dictType, //字典类型
                });
                let { data: res } = await this.$axios.seconadd(data);
                // console.log(res);
                this.addDepClick = true;
                this.addDepClickKing = false;
                if (res.code == 401) {
                    this.$router.push("/login");
                } else if (res.code == 200) {
                    this.$message({
                        message: res.msg,
                        type: "success",
                    });
                    this.rewardList();
                    this.AdddialogVisibleClose();
                } else {
                    this.$message({
                        message: res.msg,
                        type: "error",
                    });
                }
            });
        },
        searchList(val) {
            // console.log(val);
            this.nameLike = val;
            this.pageNum = 1;
            this.rewardList();
        },
        // 三级指标的搜索
        thrseList(val) {
            // console.log(val);
            this.ticSeName = val;
            this.thirpageNum = 1;
            this.tickKeshi();
        },
        thrseChange(val) {
            this.ticSeStatus = val;
            this.thirpageNum = 1;
            this.tickKeshi();
        },
        searChange(val) {
            // console.log(val);
            this.searstatus = val;
            this.pageNum = 1;
            this.rewardList();
        },
        // 科室管理分页
        handleCurrentChange(val) {
            this.pageNum = val;
            // console.log(`当前页: ${val}`);
            this.rewardList();
        },
        // 点击添加科室
        depaddKeshi() {
            this.AdddialogVisible = true;
            this.sysDictType();
        },
        dutydialogVisibleClose() {
            this.dutydialogVisible = false;
            this.dicTypeVal = "";
            this.$refs.searchdic.clear();
        },
        // 点击添加科室的时候取消正则
        AdddialogVisibleClose() {
            this.disabled = false;
            this.AdddialogVisible = false;
            this.$refs.ruleForm.resetFields();
            this.diaTit = "新增附加职责";
            this.diaBtn = "新增";
            this.dictType = "";
            this.rulBtnTit = "新增";
            this.ruleForm = {
                name: "",
                remark: "",
                status: "0",
            };
        },

        // 点击操作删除
        async deleteKeshi(row) {
            const confirmResult = await this.$confirm("确认删除？", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            }).catch((err) => err);

            if (confirmResult !== "confirm") {
                return this.$message.info("已经取消删除");
            }
            let data = _qs.stringify({
                id: row.id,
            });
            let res = await this.$axios.secondDele(data);
            // console.log(res);
            if (res.data.code == 401) {
                this.$router.push("/login");
            } else if (res.data.code == 200) {
                this.rewardList();
                this.$message({
                    message: res.data.msg,
                    type: "success",
                });
            } else {
                this.$message({
                    message: res.data.msg,
                    type: "error",
                });
            }
        },
        async tickKeshi() {
            let data = {
                pageNum: this.thirpageNum,
                pageSize: this.thirpageSize,
                indicatorSecondLibraryId: this.dytyId, //二级指标id
                type: this.dieId,
                deleteFlag: "0",
                status: this.ticSeStatus, //指标状态（0启用  1停用）
                name: this.ticSeName, //指标名称
            };
            let res = await this.$axios.thirdList(data);
            // console.log(res);
            if (res.data.code == 401) {
                this.$router.push("/login");
            } else if (res.data.code == 200) {
                // this.dutydialogVisible = true;
                this.targetdialogVisible = false;
                this.dutytableData = res.data.rows;
                this.thirtotal = res.data.total;
            } else {
                this.$message({
                    message: res.data.msg,
                    type: "error",
                });
            }
        },
        // 点击操作修改科室的添加
        async editKeshi(row) {
            // console.log(row)
            this.sysDictType();
            this.disabled = true;
            this.wardId = row.id;
            this.dytyId = row.id;
            this.indicatorLibraryId = row.indicatorLibraryId;
            this.diaBtn = "修改";
            this.rulBtnTit = "修改";
            this.diaTit = "修改附加职责";
            this.AdddialogVisible = true;
            this.ruleForm.name = row.name;
            this.ruleForm.remark = row.explain;
            this.ruleForm.status = row.status + "";
            var obj = this.nameoptions;
            // console.log(obj);
            obj.forEach((item, index) => {
                if (item.dictName == row.name) {
                    this.dictType = item.dictType;
                }
            });
        },
        // 分页显示病区列表数据
        async rewardList() {
            this.AdddialogVisible = false;
            this.diaBtn = "新增";
            this.diaTit = "新增附加职责";
            let data = {
                pageNum: this.pageNum, //页数
                pageSize: this.pageSize, //每页显示条数
                indicatorLibraryType: this.dieId, //指标类型（0基础指标 1工作量指标 2专项奖励指标 3单项补助及奖惩指标  4个人考勤指标  5附加职责）
                deleteFlag: "0", //删除状态（0正常 1已删除）
                status: this.searstatus, //状态（0启用  1停用）
                name: this.nameLike,
                id: "", //指标id
            };
            let { data: res } = await this.$axios.secondList(data);
            // console.log(res);
            if (res.code == 401) {
                this.$router.push("/login");
            } else if (res.code == 200) {
                this.tableData = res.rows;
                this.total = res.total;
            } else {
                this.$message({
                    message: res.msg,
                    type: "error",
                });
            }
        },
    },
};
</script>

<style lang="less" scoped>
.RightDiv {
    margin: 0 auto;
}

.depTitle {
    display: flex;
    margin: 0 auto;
    margin-bottom: 20px;
}
.depart-addnum {
    width: 100%;
}

.AddUser {
    float: right;
    margin-bottom: 10px;
}

.AddUserDiv {
    width: 95%;
    margin: 0 auto;
}

.depar-dia {
    width: 100%;
}
.collapse-btn {
    cursor: pointer;
    height: 30px;
    line-height: 30px;
    background: rgb(134 140 146);
}
/deep/ .el-radio-button__inner {
    width: 100px;
}
.el-pagination {
    margin-top: 20px;
}

/deep/ .el-dialog__body {
    text-align: inherit;
}

.el-main {
    .table-title {
        display: flex;
        margin-bottom: 10px;
        .index-add {
            margin-left: 20px;
        }
    }
}

.el-menu-vertical-demo:not(.el-menu--collapse) {
    width: 200px;
    min-height: 400px;
}

.hosrow {
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 10px;
}

.addtarget {
    float: right;
    margin-bottom: 20px;
}

.activeStatus {
    background: rgb(102, 204, 0);
}

.noStatus {
    background: #ccc;
}

.noStatus,
.activeStatus {
    width: 25px;
    height: 25px;
    color: white;
    line-height: 25px;
    text-align: center;
    margin: 0 auto;
}

/deep/ .el-input .el-input__inner {
    width: 220px;
}

/deep/ .el-textarea__inner,
/deep/ .el-textarea {
    width: 220px !important;
}

.btnCen {
    text-align: center;
    .el-button {
        width: 150px;
    }
}

.threSeCla {
    display: flex;
    justify-content: right;
}
</style>
